import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// TODO: SetOptional from "type-fest".
type SetOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const createSvgIconFC = (svg: React.ReactNode | (() => React.ReactNode), config?: SvgIconProps) => React.forwardRef<SVGSVGElement, SetOptional<SvgIconProps, any>>(({ ...otherProps }, ref): JSX.Element => (
  <SvgIcon {...config} {...otherProps} ref={ref}>
    {typeof svg === "function" ? svg() : svg}
  </SvgIcon>
));