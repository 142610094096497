import create from 'zustand';

export enum Lore {
  world,
  city,
  slums,
  blank
}

interface NavigationState {
  lore: Lore
  setLore: (lore: Lore) => void
  nextLore: () => void,
  prevLore: () => void,
}

export const useLoreSubMenuStore = create<NavigationState>(set => ({
  lore: Lore.world as Lore,
  prevLore: () => set((state) => ({ lore: (state.lore > 0 ? (state.lore - 1) : Lore.blank - 1) as Lore})),
  nextLore: () => set((state) => ({ lore: (state.lore < (Lore.blank - 1) ? (state.lore + 1) : 0) as Lore})),
  setLore: (lore: Lore) => set({ lore })
}))