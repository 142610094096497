import create from 'zustand';

export enum Page {
  landing,
  info,
  intro,
  factions,
  contacts,
}

export interface NavigationState {
  page: Page
  setPage: (page: Page) => void
  references: Record<Page, any>
  setReference: (page: Page, ref: any) => void,
}

export const useNavigationStore = create<NavigationState>(set => ({
  page: Page.landing as Page,
  references: {
    [Page.landing]: null,
    [Page.info]: null,
    [Page.intro]: null,
    [Page.factions]: null,
    [Page.contacts]: null,
  },
  setReference: (page: Page, ref: any) => set((state) => ({ references: { ...state.references, [page]: ref }  })),
  setPage: (page: Page) => set((state) => {
    if (state.references[page]) {
      state.references[page].current.scrollIntoView()
    }
    return { page }
  })
}))