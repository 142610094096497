import { css } from '@emotion/css';
import { keyframes } from '@emotion/react';
import { createTheme } from '@mui/material';

const theme = createTheme();

const bounce = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  20% {
    transform: translate3d(0, 10px, 0);
  }
`

const bounceUp = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  20% {
    transform: translate3d(0, -10px, 0);
  }
`

const bounceLeft = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  20% {
    transform: translate3d(-10px, 0, 0);
  }
`

const bounceRight = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  20% {
    transform: translate3d(10px, 0, 0);
  }
`

export const root = css({
  cursor: 'pointer',
  animation: `${bounce} 3s ease infinite`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '28px',
  userSelect: 'none',
  width: '56px',
  height: '56px',
  padding: '5',
  minWidth: '0',
});

export const arrow = css({
  userSelect: 'none',

  '&:focus': {
    outline: 'none',
  },
})

export const rotate180 = css({
  animation: `${bounceUp} 3s ease infinite`,
})

export const rotate90 = css({
  animation: `${bounceLeft} 3s ease infinite`,
})

export const rotate270 = css({
  animation: `${bounceRight} 3s ease infinite`,
})

export const rotateArrow180 = css({
  transform: 'rotate(180deg)',
})

export const rotateArrow90 = css({
  transform: 'rotate(90deg)',
})

export const rotateArrow270 = css({
  transform: 'rotate(270deg)',
})

export const smallImg = css({
})

export const small = css({
  borderRadius: '15px',
  width: '30px',
  height: '30px',
  padding: '0',
})

export const isBlackArrow = css({
  color: theme.palette.text.primary,
})

export const isBlack = css({})