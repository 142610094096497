import { ListItemButton, ListItemText, Typography } from '@mui/material';

interface Props {
  onClick: () => void,
  children: React.ReactNode,
}

export const MenuBodyItem: React.FC<Props> = ({children, onClick}) => (
  <ListItemButton dense onClick={onClick}>
    <ListItemText primary={<Typography align="center" variant="h3">{children}</Typography>} disableTypography />
  </ListItemButton>
)