import { css } from '@emotion/css';
import { createTheme } from '@mui/material';
import { bp, bpValues } from '../../theme/theme';

const theme = createTheme();

export const root = css({
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  minWidth: '100%',
  zIndex: '100',
  padding: theme.spacing(2, 2, 0, 2),
  height: '150px',
  background: 'linear-gradient(180deg, rgba(0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 90%,  rgba(0, 0, 0, 0) 100%)',
  maxWidth: `${bpValues.xl}px`,
  overflow: 'hidden',
  [bp.lg]: {
    display: 'flex',
  },
})

export const menu = css({
  backgroundImage: 'url("/images/elements/Decoration.png")',
  paddingTop: theme.spacing(5),
  maxWidth: `Calc(${bpValues.xl}px - (145px * 2))`,
})

export const leftCorner = css({
  backgroundImage: 'url("/images/elements/LeftCornerDecoration.png")',
  width: '127px',
  height: '124px',
})

export const rightCorner = css({
  backgroundImage: 'url("/images/elements/RightCornerDecoration.png")',
  width: '127px',
  height: '124px',
})