import * as styles from './info.style';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { Page } from '../../Stores/useNavigationStore';
import { Layout } from '../../Components/Layout/Layout';
import { ArrowColor } from '../../Components/Arrow/Arrow';

export const Info: React.FC = () => {
  return (<Layout next={Page.intro} index={Page.info}  prev={Page.landing} className={styles.root} arrowColor={ArrowColor.black}>
    <Grid container className={styles.data} justifyContent="center" spacing={2} alignItems="center">
      <Grid item lg={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Grid container justifyContent="center" direction="column" alignItems="center" spacing={1}>
              <Grid item xs>
                <Box className={styles.imageTop} />
                  </Grid>
                  <Grid item xs>
                    <img alt="LARP" src="/images/poster/poster-maija.jpg" className={styles.image} />
                  </Grid>
                  <Grid item xs>
                <Box className={styles.imageBottom} />
              </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Paper className={styles.paper}>
          <Typography variant="h3" align="center">About the game</Typography>
          <Typography align="left"><strong>When?</strong> 15 -17 July</Typography>
          <Typography align="left"><strong>Where?</strong> Suntaži poligon, Latvia</Typography>
          <Typography align="left"><strong>Game language?</strong> English</Typography>
          <Typography align="left"><strong>Setting?</strong> Grimdark post apocalyptic fantasy</Typography>
          <Typography align="left"><strong>Price? 40 euros</strong></Typography>
          <br />
          <Typography align="left" variant="h5">Read full rules <Link href="https://docs.google.com/document/d/1QjxQ-MlKvrX62BEkY8qY2adQNNfu5FZ_qioBgyCiVNs/edit?usp=sharing"><strong>here</strong></Link>.</Typography>

          <Typography align="left" variant="h5">Roles:</Typography>
          <Typography align="left">
            There are no prewritten roles for the game. This will be a faction game. You can start the game as part of a specific faction or be factionless. The starting factions are as follows: 3 main cults, hunters and agents. During the game you will be able to make your own cults or join existing factions. Before the game factions will have a hard cap of maximum members. The sooner you register, the higher the chances to get into the faction you want at the start of the game.
          </Typography>
          <Typography align="left">
            When applying you can write your character's backstory. If your backstory is interesting (doesn't mean long) and fits the world, then GMs might make a personal quest for you.
          </Typography>
          <Typography align="left" variant="h5">About the game:</Typography>
        <Typography align="left">
          Action will happen in the slums of Cradle city. Cradle is a huge city known for trading guilds and many religions. Citizens dislike people who live in the slums. People from the slums try to get to live in the city.
        </Typography>
        <Typography align="left">
          Technology is evil. It has brought upon all this suffering and destruction. Magic on the other hand is good and pure. It’s a mystery how it works, but it’s generally believed that it shall bring salvation to all.
        </Typography>
          <Typography align="left">
          This is a low magic setting. Mostly magic can be found in ancient artefacts or produced by rituals.
          </Typography>
          <Typography align="left">
            Game will be intrigue oriented as each faction will have their own goal. However, fighting is possible as well. Players will know exact faction goals only when the game starts. Players can join factions before or during the game.
          </Typography>
          <Typography>
            <strong>There can be a maximum of 80 players at the game.</strong>
          </Typography>
            <Typography align="left" variant="h5">Aesthetics for the costumes:</Typography>
        <Typography align="left">
          Any costume that is suitable for medieval, fantasy or post apocalyptic LARP. You can mix them up as well. You can pose as any fantasy race you want. Race might give you a special racial quest if your play style and appearance is considered epic by GMs. Armour doesn’t give any bonuses in PvP battles, but might be considered when an NPC engages you in battle or gives protection from certain Agent abilities.
        </Typography>
          <Typography align="left">
            NPCs will consider your appearance when interacting with you.
          </Typography>
          <Typography align="left" variant="h5">Weapons:</Typography>
        <Typography align="left">
          Everyone is allowed to bring their own LARP (soft) knives and daggers. Players within the Hunter faction can bring any LARP weapon except nerf gun, spear, shield or bow. Players within the Agent faction can bring any LARP weapon except spear, shield or bow.
        </Typography>
          <Typography align="left">
          At the game there will be NPCs who drop lootable nerf guns with limited ammunition. All weapons that are lootable can be used by any player disregarding the faction.
          </Typography>
          <Typography align="left">
            GMs have final say of use of any equipment you bring to the game.
          </Typography>
          <Typography align="left">
            Please read weapon rules for further clarification. (Weapon rules will be posted later.)
          </Typography>
            <Typography align="left" variant="h5">Game location rules:</Typography>
        <Typography align="left">
          Game rules do not cancel laws of the Republic of Latvia.
        </Typography>
          <Typography align="left">
          Any form of intoxication is forbidden at the game location. If you are visibly intoxicated you will be escorted from the game location.
          </Typography>
          <Typography align="left">
            Players can’t bring to the game location real knives or other sharp items even as part of outfits or decoration. These items can be stored and used in your tent/car outside of the game location. Any kind of firearms are forbidden.
          </Typography>
          <Typography align="left">
            Players can’t take children or pets.
          </Typography>
          <Typography align="left">
            Players are responsible for their own safety during the game.
          </Typography>
          <br />
          <Typography align="left">
            <strong>By attending the game you accept that during the event you will be photographed or filmed. These materials can be published and  used for promotional purposes, without additional inquiries.</strong>
          </Typography>
          <br />
          <Typography align="left">
            <strong>By attending the game you accept GMs rules and rulings.</strong>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  </Layout>)
}

