import { createTheme, lighten, responsiveFontSizes, ThemeOptions } from '@mui/material';

export const bpValues = {
  xs: 0,
  sm: 576,
  md: 760,
  lg: 1200,
  xl: 1980,
}

export const bp = {
  xs: `@media (min-width: ${bpValues.xs}px)`,
  sm: `@media (min-width: ${bpValues.sm}px)`,
  md: `@media (min-width: ${bpValues.md}px)`,
  lg: `@media (min-width: ${bpValues.lg}px)`,
  xl: `@media (min-width: ${bpValues.xl}px)`
}

const themeBase = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    error: {
      main: '#a4161a'
    },
    background: {
      default: '#ffffff',
      paper: 'rgba(177, 141, 98, 0.5)',
    }
  }
} as ThemeOptions)

export const rosTheme = responsiveFontSizes(createTheme(themeBase, {
  breakpoints: {
    values: {
      ...bpValues
    },
  },
  typography: {
    h1: {
      fontFamily: "strokes_roughregular",
      color: themeBase.palette.error.main,
      fontSize: '6.4rem',
    },
    h2: {
      fontFamily: "strokes_roughregular",
      color: themeBase.palette.error.main,
      fontSize: '4.4rem',
    },
    h3: {
      fontFamily: "strokes_roughregular",
      color: themeBase.palette.error.main,
      fontSize: '2.4rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    h4: {
      fontSize: '1.7rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    h5: {
      fontSize: '1.4rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        style: {
          borderRadius: themeBase.spacing(1),
          padding: themeBase.spacing(1),
        }
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: 'primary',
        variant: 'outlined',
        size: 'large',
      },
  },
    MuiContainer: {
      defaultProps: {
        style: {
          maxWidth: `${bpValues.xl}px`,
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        style: {
          color: themeBase.palette.text.secondary,
          backgroundColor: lighten(themeBase.palette.text.primary, 0.2),
        }
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        style: {
          color: lighten(themeBase.palette.error.main, 0.4),
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#161a1d',
          color: themeBase.palette.text.secondary,
        }
      },
    },
}}));
