import { Grid } from '@mui/material';
import { MenuBodyItem } from './MenuBodyItem';
import { Page } from '../../Stores/useNavigationStore';
import { useDialog } from '../../Stores/useDialog';

interface Props {
  close?: boolean
}

export const MenuRowBody: React.FC<Props> = ({ close = false }) => {
  const { toogleRegister } = useDialog();

 return (<Grid container wrap="nowrap" justifyContent="space-between">
   <MenuBodyItem onClick={toogleRegister}>Register</MenuBodyItem>
   <MenuBodyItem pageName={Page.info}>About</MenuBodyItem>
   <MenuBodyItem pageName={Page.intro}>the World</MenuBodyItem>
   <MenuBodyItem pageName={Page.factions}>Factions</MenuBodyItem>
   <MenuBodyItem pageName={Page.contacts}>Contacts</MenuBodyItem>
  </Grid>)
}