import React from 'react';
import './App.css';
import { Main } from './Views/Main/Main';
import { Info } from './Views/Info/Info';
import { Menu } from './Components/Menu/Menu';
import { MenuDrawer } from './Components/MenuDrawer/MenuDrawer';
import { Factions } from './Views/Factions/Factions';
import { PlotIntro } from './Views/PlotIntro/PlotIntro';
import { NavigationRow } from './Components/NavigationRow/NavigationRow';
import { Registration } from './Views/Registration/Registration';
import { Contacts } from './Views/Contacts/Contacts';
import {Routes, Route} from "react-router-dom";
import {Login} from "./Views/Login/Login";

function App() {
  return (
      <Routes>
          <Route path="/" element={<div className="App">
              <NavigationRow />
              <Main />
              <Info />
              <PlotIntro />
              <Factions />
              <Contacts />
              <Menu />
              <MenuDrawer />
              <Registration />
          </div>} />
          <Route path="/artefact" element={<Login />} />
      </Routes>
  );
}

export default App;
