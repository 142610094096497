import * as styles from './PlotIntro.style';
import { Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Page } from '../../Stores/useNavigationStore';
import { useBreakpoint } from '../../theme/useBreakpoint';
import { Layout } from '../../Components/Layout/Layout';
import { Lore, useLoreSubMenuStore } from '../../Stores/useLoreSubMenuStore';
import { PlotRow } from './PlotRow';
import classnames from 'classnames';

export interface LoreInfo {
  title: string,
  text: React.ReactNode,
  id: Lore,
}

const loreInfo: LoreInfo[] = [
  {
    title: 'The World',
    id: Lore.world,
    text: <>
      <Typography align="left">
        Dear child, do not worry. All will be fine. Sleep now. ..a story? What went before? Even before time? Alright, lay down, listen, and take lessons of the past to your heart.
      </Typography>
      <br />
      <Typography align="left">It all started with a bang. The big bang, where the spirit of war went mad in anger at the spirit of spite and spewed all his nose hairs in the air, and with the flame of judgement those rain down the land with adominian power. Many souls then blended together into mutated monsters that have roamed this world since then. And still, even after Morgul the conqueror killed so many of them and enslaved them, even more lurk in the darkest corners of every apostasy garden, where cursed technology lies.</Typography>
      <br />
      <Typography align="left">Morgul, my dear? Ah yes. He was a mighty warrior. When the first gnomes woke up from eternal slumber, he was the one, who united all banners of free souls to wage war against mutants and technomancers of the Gersthald empire, but I told you this story already yesterday, my dear.</Typography>
      <br />
      <Typography align="left">Now, where was I? Ah yes. The big bang. That was the first end. Then, the second end, was, when the colossus of Ayergnes shed his blood all over this world, so in the end, only the third of all living survived, blessed by a celestial cocoon that engulfed them in time and space, till puked out.</Typography>
      <Typography align="left">The third end when there was the nansies rebellion. No one knows more about those, just that those were the smallest fairies that the world had ever seen.</Typography>
      <Typography align="left">The fourth end was emergence. The fifth was thanks to Kraken of Slob.</Typography>
      <Typography align="left">I think, dear there were two or three more, as I heard, but no elf remembers that. But the last one was right before Rondell the seafarer took in his flying galleon everything, that lives and is not a pesky mutant, and went on a long journey in the northern lights into the darkness, where only stars play eternal games.</Typography>
      <br />
      <Typography align="left">And then we woke up again here, my dear. Elves, Dwarves, Gnomes, and all the Faerie barbarians that roam this land. Shortlings and the Catfolk. Urks and Smellings. Dogsters and the mighty Platypuses. And every other folk, that we even cannot count, my dear, and we made this land live again.  Live with trees and grass, and even Eichollons, that bless our water, purified of taint, that technology brings forth, so our souls would not be ripped apart from our shells.</Typography>
      <br />
      <Typography align="left">Ah, you are asleep already… good. Tomorrow I will tell you another story. Story how gods blinked and how pillars of reality were shaken, but that will be tomorrow.</Typography>
      <br />
      <Typography align="left">Sleep now.</Typography>
    </>
  },
  {
    title: 'The City',
    id: Lore.city,
    text: <>
      <Typography align="left">
        The Cradle we know as first - a city born out of necessity. After the whole world was practically destroyed the 7 Great Families gathered together. Their aim was to build a great city, a beacon of hope for all the living beings. They chose the best possible location, an intersection between different nations, or what has been left of them.
      </Typography>
        <br />
      <Typography align="left">
        Before the first disasters struck there was a very active trading city in this exact location. The nearby sea and river facilitated trade with nations near and far. But after the numerous disasters the sea dried up and the river got polluted by toxic waste of previously unknown origin. Only a few remnants from the previous city remained. Just a reminder of the previous glory.
      </Typography>
      <br />
      <Typography align="left">
      The 7 Great Families took upon them the task to revive a humane living for themself. They build upon ruins a new city, surrounded by walls. All creatures were welcome to the city if they could prove their worth to the 7 Great Families. There was no particularly strong discrimination against different beings as everyone is exploitable except gnomes. Now they govern the city and form the city council from the 7 Great Family representatives.
      </Typography>
      <br />
      <Typography align="left">
      As the city grew on old ruins, some citizens found ancient inscriptions about entities who were worshipped by previous civilizations. There were many who sought to worship these entities again - many cults formed in the city. Over time cults grew so numerous and gained so much influence that the city council wasn't able to ignore them anymore. The Raven family was tasked with solving the issue - so they created an organisation called Hunters.
      </Typography>
      <br />
      <Typography align="left">
      Everyone in the Cradle city knows that evil technology will consume your soul! Only exception are Hunters. They are selectively chosen for their souls, so technology doesn't affect them so dreadly. Or if you are an Agent.
      </Typography>
      <br />
      <Typography align="left">
      Citizens of Cradle don't like people who live in slums. These creatures only try to get their hands on the city's already limited resources.
      </Typography>
    </>
  },
  {
    title: 'Slums',
    id: Lore.slums,
    text: <>
  <Typography align="left">
      The slums are a weird and dangerous place. Unexplainable events happen here… It looks like a dumpster, but you can find ancient writings with unreadable symbols. weirdly looking creatures live there - sometimes they attack you, but sometimes heal you, there are ruins of buildings that no one can recognize. There is a legend about one of those buildings - this building is a prison to an ancient Evil.
  </Typography>
      <br />
      <Typography align="left">
      Legends also tells of an order of mages called “Atomkraft” that used to perform a ritual once in a ten years to contain this Evil. Somehow they were able to tell the exact time when the Evil will break its chains.
      </Typography>
      <br />
      <Typography align="left">
      Nosy citizens and slum creatures always accompanied the order to watch the ritual. But almost everyone who knew something more about this order died right after the last ritual in mysterious circumstances. There are rumours that Agents were involved.
      </Typography>
      <br />
      <Typography align="left">
      After this incident around the old ritual ground people found scattered tablets with weird symbols on them. Some say that these tablets were used in order's ritual, and if you gather all the parts, you can learn how to control imprisoned Evil.
      </Typography>
      <br />
      <Typography align="left">
      There is one recent anomalie in the slums - no one can really die there. People who are supposed to be dead resurrect. Sometimes they don't remember anything, sometimes they have full memory, some of them return with hideous mutations, and some feel like an empty shell.
      </Typography>
    </>
  }
];

export const PlotIntro: React.FC = () => {
  const { setLore, lore } = useLoreSubMenuStore();

  const lgUp = useBreakpoint('lg');

  return (<Layout next={Page.factions} index={Page.intro} prev={Page.info} className={styles.root}>
    <Grid container direction="row" className={styles.data} wrap={lgUp ? "nowrap" : "wrap"} alignItems="center" spacing={4}>
      {lgUp && (<Grid item className={styles.arrow} lg={4}>
        <img alt="Bottom decoration" src="/images/elements/subNavTop.png" className={styles.subNavTop} />
        <Paper className={styles.subMenu}>
          <Button size="small" variant="text" fullWidth className={classnames(styles.subMenuButton, { [styles.active]: lore === Lore.world })} onClick={() => setLore(Lore.world)}>The World</Button>
          <Button size="small" variant="text" fullWidth className={classnames(styles.subMenuButton, { [styles.active]: lore === Lore.city })} onClick={() => setLore(Lore.city)}>The City</Button>
          <Button size="small" variant="text" fullWidth className={classnames(styles.subMenuButton, { [styles.active]: lore === Lore.slums })} onClick={() => setLore(Lore.slums)}>Slums</Button>
        </Paper>
        <img alt="Top decoration" src="/images/elements/subNavBottom.png" className={styles.subNavBottom} />
      </Grid>)}
      {(!lgUp && (<Grid item xs={12}>
        <Tabs classes={{ indicator: styles.indicator, scrollButtons: styles.scroller }} variant="scrollable" centered indicatorColor="secondary" allowScrollButtonsMobile selectionFollowsFocus value={lore} onChange={(event: React.SyntheticEvent, newValue: number) => {
          setLore(newValue);
        }} aria-label="basic tabs example">
          {loreInfo.map((currentLore) => (<Tab value={currentLore.id} label={<Typography className={styles.tabText} variant="h3">{currentLore.title}</Typography>} />))}
        </Tabs>
      </Grid>))}
      <Grid item lg={8} xs className={styles.info}>
          <Grid container>
            {loreInfo.map((loreItem) => <PlotRow visible={loreItem.id === lore}  key={loreItem.title} {...loreItem} />)}
          </Grid>
      </Grid>
    </Grid>
  </Layout>)
}