import { css } from '@emotion/css';
import { createTheme } from '@mui/material';
import { bp } from '../../theme/theme';

const theme = createTheme();

export const root = css({
  position: 'fixed',
  top: 0,
  right: 0,
  [bp.lg]: {
    display: 'none',
  }
})

export const icon = css({
  fontSize: `${theme.typography.pxToRem(40)} !important`,
})

export const button = css({
  padding: `15px 10px 10px 10px`,
  minWidth: '0'
})