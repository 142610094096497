import create from 'zustand';

export enum DrawerState {
  open,
  closed,
}

export interface DrawerStateProps {
  drawerState: DrawerState
  toggleDrawer: () => void
}

export const useDrawerState = create<DrawerStateProps>((set) => ({
  drawerState: DrawerState.closed as DrawerState,
  toggleDrawer: () => set((state) => ({ drawerState: state.drawerState === DrawerState.open ? DrawerState.closed : DrawerState.open }))
}))