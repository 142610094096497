import { css } from '@emotion/css';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const button = css({
  fontFamily: "strokes_roughregular",
  color: `#ffffff`,
  fontSize: `${theme.typography.pxToRem(40)}`,
  cursor: 'pointer'
})

export const selected = css({
  color: `${theme.palette.error.main} !important`,
})

