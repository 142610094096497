import { LoreInfo } from './PlotIntro';
import { Grid, Paper, Typography } from '@mui/material';
import * as styles from './PlotIntro.style';

interface PlotRowProps extends LoreInfo {
  visible: boolean,
}

export const PlotRow: React.FC<PlotRowProps> = ({ title, text, visible }) => (visible ? (<Grid xs={12} flex="0 0 100%" className={styles.row} item>
  <Paper className={styles.paper}>
    <Typography className={styles.textTitle} variant="h3" align="center">{title}</Typography>
    <Typography align="left">{text}</Typography>
  </Paper>
</Grid>) : null)