import { css } from '@emotion/css';
import { createTheme } from '@mui/material';
import { bp } from '../../theme/theme';

const theme = createTheme();

export const root = css({
  backgroundImage: 'url("/images/elements/blood2.png"), url("/images/poster/background.jpg")',
  backgroundRepeat: 'no-repeat, repeat !important',
  backgroundSize: 'initial !important',
  backgroundPosition: 'top -30px right, top left',
  [bp.md]: {
    backgroundImage: 'url("/images/elements/tree.png"), url("/images/elements/blood2.png"),  url("/images/elements/blood1.png"), url("/images/elements/patternOrnament.png"), url("/images/poster/background.jpg")',
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat, repeat-y, repeat !important',
    backgroundPosition: 'right 20px bottom, top -30px right, bottom -85px left, right 8px top, top left !important',
  }
});

export const image = css({
  maxWidth: '100%',
  boxSizing: 'border-box',
  maxHeight: '600px',
})

export const arrowUp = css({
  paddingTop: theme.spacing(2.5),
  height: theme.spacing(10),
})

export const paper = css({
  [bp.md]: {
    marginRight: theme.spacing(7),
  }
})

export const data = css({
  paddingTop: theme.spacing(12),
  minHeight: '100%',
})

export const imageTop = css({
  backgroundImage: 'url("/images/elements/topDecoration.png")',
  width: '281px',
  height: '143px',
  display: 'none',
  [bp.lg]: {
    display: 'flex',
  }
})

export const imageBottom = css({
  backgroundImage: 'url("/images/elements/bottomDecoration.png")',
  width: '281px',
  height: '143px',
  display: 'none',
  [bp.lg]: {
    display: 'flex',
  }
})

export const important = css({
  color: '#a4161a'
})

export const larger = css({
  fontSize: "1.5rem",
})



