import { css } from '@emotion/css';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const paper = css({
  backgroundColor: `rgba(0, 0, 0, 0.7)`,
  width: "100%",
})

export const button = css({
  padding: `15px 10px 10px 10px`,
  minWidth: '0'
})

export const icon = css({
  fontSize: `${theme.typography.pxToRem(40)} !important`,
})
