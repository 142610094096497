import { css } from '@emotion/css';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const root = css({
  width: '100%',
  minHeight: '100%',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: "center",
  overflow: 'auto',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  display: 'flex',
});

export const data = css({
  minHeight: '100%',
})

export const container = css({
  maxWidth: '100% !important',
  width: '100%',
});
