import * as styles from './arrow.style';
import { Button } from '@mui/material';
import classnames from 'classnames';
import { ArrowSvg } from './icons'

export enum ArrowDirection {
  up,
  down,
  left,
  right
}

export enum ArrowColor {
  black,
  white
}

interface Props {
  direction?: ArrowDirection
  onClick: () => void
  small?: boolean
  color?: ArrowColor
}

export const Arrow: React.FC<Props> = ({ onClick, small = false, direction = ArrowDirection.down, color = ArrowColor.white }) => {
  return (
    <Button color="primary" variant="text" className={classnames(styles.root, {
      [styles.small]: small,
      [styles.rotate180]: direction === ArrowDirection.up,
      [styles.rotate90]: direction === ArrowDirection.left,
      [styles.rotate270]: direction === ArrowDirection.right,
      [styles.isBlack]: color === ArrowColor.black
    })} onClick={onClick}>
      <ArrowSvg
        fontSize={small ? "medium" : "large"}
        className={classnames(styles.arrow, {
          [styles.smallImg]: small,
          [styles.rotateArrow180]: direction === ArrowDirection.up,
          [styles.rotateArrow90]: direction === ArrowDirection.left,
          [styles.rotateArrow270]: direction === ArrowDirection.right,
          [styles.isBlackArrow]: color === ArrowColor.black
        })}
      />
    </Button>)
}