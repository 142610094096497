import * as styles from './login.style';
import {
    Button,
    Grid,
    TextField,
} from '@mui/material';
import { Page } from '../../Stores/useNavigationStore';
import { Layout } from '../../Components/Layout/Layout';
import { ArrowColor } from '../../Components/Arrow/Arrow';
import {Field, Form, Formik, useFormikContext} from "formik";
interface FormValues {
    code: string
}

const LoginInside: React.FC = () => {
    const { errors, isSubmitting } = useFormikContext<FormValues>()

    return (<Layout next={Page.intro} index={Page.info}  prev={Page.landing} className={styles.root} arrowColor={ArrowColor.black}>
        <Form>
            <Grid container justifyContent="center" spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        name="code"
                        label="Code"
                        as={TextField}
                        error={errors.code}
                    />
                </Grid>
               <Grid item><Button disabled={isSubmitting} type="submit" variant="contained" color="secondary">Reveal</Button></Grid>
            </Grid>
        </Form>
    </Layout>)
}

export const Login: React.FC  = () => {
    return (<>
            <Formik
                validateOnBlur={false}
                initialValues={{
                    code: '',
                } as FormValues}
                validate={() => ({})}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(values)
                    };

                    fetch('https://api.kaslauns.lv/login.php', requestOptions)
                        .then(async (response) => {
                        })
                        .catch(() => {
                            setErrors({ 'code': 'Incorrect Agents Code!' })
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                <LoginInside />
            </Formik>
        </>
    )
}

