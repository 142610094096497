import { css } from '@emotion/css';
import { createTheme } from '@mui/material';
import { bp } from '../../theme/theme';

const theme = createTheme();

export const root = css({
  backgroundImage: 'url("/images/poster/contacts_bg.jpg")',
  backgroundPosition: "center",
  [bp.lg]: {
    backgroundImage: 'url("/images/poster/contacts_bg.jpg")',
  }
});

export const data = css({
  minHeight: '100%',
  width: '100%'
})

export const paper = css({
  padding: `${theme.spacing(3)} !important`,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#ffffff',
  [bp.lg]: {
    marginTop: theme.spacing(18),
    marginLeft: theme.spacing(10),
  }
})