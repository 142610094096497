import create from 'zustand';

export enum Faction {
  hunters = 0,
  agents = 1,
  civil = 2,
  cult1 = 3,
  cult2 = 4,
  cult3 = 5,
  blank
}

export interface NavigationState {
  faction: Faction
  setFaction: (faction: Faction) => void
  nextFaction: () => void,
  prevFaction: () => void,
}

export const useSubMenuStore = create<NavigationState>(set => ({
  faction: Faction.hunters as Faction,
  prevFaction: () => set((state) => ({ faction: (state.faction > 0 ? (state.faction - 1) : Faction.blank - 1) as Faction})),
  nextFaction: () => set((state) => ({ faction: (state.faction < (Faction.blank - 1) ? (state.faction + 1) : 0) as Faction})),
  setFaction: (faction: Faction) => set({ faction })
}))