import { css } from '@emotion/css';
import { bp } from '../../theme/theme';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const root = css({
  backgroundImage: 'url("/images/elements/skull.png"), url("/images/poster/background.jpg")',
  backgroundRepeat: 'no-repeat, repeat',
  backgroundSize: 'initial',
  backgroundPosition: 'bottom 10px left -225px, top left',
  [bp.md]: {
    backgroundImage: 'url("/images/elements/skull.png"), url("/images/elements/blood3.png"), url("/images/elements/patternOrnament.png"), url("/images/poster/background.jpg")',
    backgroundRepeat: 'no-repeat, no-repeat, repeat-y, repeat',
    backgroundSize: 'initial',
    backgroundPosition: 'bottom 10px left -225px, left -170px bottom 340px, right 8px top, top left',
  }
});

export const factionContainer = css({
  width: "100%",
  overflow: 'hidden',
  [bp.md]: {
    marginRight: theme.spacing(7),
  }
});

export const arrow = css({
  position: 'relative'
})

export const data = css({
  [bp.lg]: {
    minHeight: '100%',
  }
})

export const textTitle = css({
  display: "none",
  [bp.lg]: {
    display: "block",
  }
})

export const subMenu = css({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(14),
  padding: `${theme.spacing(4)} !important`,
})

export const subMenuButton = css({
  fontFamily: "strokes_roughregular",
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(30),
  textAlign: "center"
})

export const subNavTop = css({
  position: 'absolute',
  top: "19px",
  left: "Calc(50% - 62px)",
})

export const subNavBottom = css({
  position: 'absolute',
  bottom: "-12px",
  left: "Calc(50% - 62px)",
})

export const active = css({
  color: `#a4161a !important`
})

export const row = css({
  padding: theme.spacing(0, 1),
  maxWidth: "100%",
})

export const indicator = css({
  backgroundColor: '#a4161a'
})

export const tabText = css({
  margin: 0,
})

export const scroller = css({
  color: '#a4161a',
  "& svg": {
    fontSize: theme.typography.pxToRem(40),
  }
})

export const tabs = css({
  [bp.md]: {
    paddingRight: theme.spacing(7),
  }
})