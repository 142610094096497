import { css } from '@emotion/css';
import { bp, bpValues } from '../../theme/theme';

export const root = css({
  width: '100%',
  height: '100%',
  backgroundColor: '#000000',
  position: 'relative',
});

export const background = css({
    position: 'relative',
    margin: '0 auto',
    backgroundImage: 'url("/images/poster/poster.webp")',
    width: '100%',
    height: '100%',
    maxWidth: `${bpValues.xl}px`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
})

export const title = css({
  position: 'absolute',
  top: '60px',
  left: '40px',
  right: '40px',
  transform: 'rotate(-10deg)',
  transition: "all 1s ease",
  [bp.sm]: {
    transform: 'rotate(-15deg)',
  },
  [bp.md]: {
    left: '150px',
  }
})

export const subtitle = css({
  position: 'absolute',
  top: '45%',
  left: '25%',
  transform: 'rotate(-15deg)',
})

export const arrow = css({
  margin: '-90px auto 0 auto',
  display: 'flex',
  justifyContent: 'center',
})