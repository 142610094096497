import { css } from '@emotion/css';

export const root = css({
});

export const paper = css({
  backgroundColor: '#161a1d'
});

export const close = css({
  position: "absolute",
  right: "10px",
  top: "10px",
});

export const container = css({
  position: "relative",
})

export const faction = css({
  marginBottom: "24px",
})

export const reason = css({
  paddingTop: "0 !important",
})
