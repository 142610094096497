import { Box, Button, Drawer } from '@mui/material';

import { DrawerState, useDrawerState } from '../../Stores/useMenuDrawer';
import { MenuBody } from '../MenuBody/MenuBody';
import * as styles from './drawer.style';
import CloseIcon from '@mui/icons-material/Close';

export const MenuDrawer: React.FC = () => {
  const { drawerState, toggleDrawer } = useDrawerState();

  return (<Drawer
    classes={{paper: styles.paper}}
    anchor="right"
    open={drawerState === DrawerState.open}
    onClose={toggleDrawer}
  >
    <Box justifyContent="flex-end" display="flex">
      <Button
        variant="text"
        size="large"
        onClick={toggleDrawer}
        startIcon={<CloseIcon
          fontSize="large"
          color="error"
          className={styles.icon}
        />}
        className={styles.button}
      />
    </Box>
    <MenuBody close />
  </Drawer>)
}