import { DrawerState, useDrawerState } from '../../Stores/useMenuDrawer';
import { Box, Button } from '@mui/material';
import * as styles from './menu.style';
import MenuIcon from '@mui/icons-material/Menu';

export const Menu: React.FC = () => {
  const { toggleDrawer, drawerState } = useDrawerState();

  return (<Box className={styles.root}>
    {drawerState === DrawerState.closed &&
      (<Button
        className={styles.button}
        startIcon={<MenuIcon className={styles.icon} />}
        variant="text"
        onClick={toggleDrawer}
      />)
    }
  </Box>)
}