import create from 'zustand';

export enum RegisterState {
  open,
  closed,
}

export interface RegisterStateProps {
  registerState: RegisterState
  toogleRegister: () => void
}

export const useDialog = create<RegisterStateProps>((set) => ({
  registerState: RegisterState.closed as RegisterState,
  toogleRegister: () => set((state) => ({ registerState: state.registerState === RegisterState.open ? RegisterState.closed : RegisterState.open }))
}))