import { css } from '@emotion/css';
import { createTheme } from '@mui/material';
import { bp } from '../../theme/theme';

const theme = createTheme();

export const root = css({
  backgroundImage: 'url("/images/poster/plotIntro.webp")',
  backgroundPosition: "left",
  [bp.lg]: {
    backgroundImage: 'linear-gradient(105deg, rgba(16, 12, 15,0) 0%, rgba(16, 12, 15,0) 46%, rgba(16, 12, 15,0.9) 57%, rgba(16, 12, 15,1) 100%), url("/images/poster/plotIntro.webp")',
  }
});

export const image = css({
  maxWidth: '100%',
  boxSizing: 'border-box',
})

export const data = css({
  [bp.lg]: {
    minHeight: '100%',
  }
})

export const arrowUp = css({
  paddingTop: theme.spacing(2.5),
  height: theme.spacing(10),
})

export const paper = css({
  padding: `${theme.spacing(3)} !important`,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#ffffff',
  overflow: "hidden",
  maxWidth: "100%",
  [bp.lg]: {
    marginRight: theme.spacing(13),
  }
})

export const info = css({
  paddingTop: theme.spacing(16),
  overflow: "hidden",
})

export const lore = css({
  transition: 'all 100ms ease',
});

export const loreContainer = css({
  width: "100%",
  maxWidth: "100%",
  overflow: 'hidden',
  [bp.lg]: {
    marginRight: theme.spacing(7),
  }
});

export const arrow = css({
  position: 'relative'
})

export const removeSpacing = css({
  paddingLeft: `0 !important`,
})

export const subMenu = css({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(14),
  padding: `${theme.spacing(4)} !important`,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
})

export const subMenuButton = css({
  fontFamily: "strokes_roughregular",
  color: '#ffffff',
  fontSize: theme.typography.pxToRem(30),
  textAlign: "center"
})

export const subNavTop = css({
  position: 'absolute',
  top: "19px",
  left: "Calc(50% - 62px)",
  maxWidth: "100%",
})

export const subNavBottom = css({
  position: 'absolute',
  bottom: "-12px",
  left: "Calc(50% - 62px)",
})


export const row = css({
  padding: theme.spacing(0, 1),
  maxWidth: "100%",
})

export const active = css({
  color: `${theme.palette.error.main} !important`
})

export const textTitle = css({
  display: "none",
  [bp.lg]: {
    display: "block",
  }
})

export const indicator = css({
  backgroundColor: '#ffffff'
})

export const tabText = css({
  margin: 0,
  color: '#ffffff',
})

export const scroller = css({
  color: '#ffffff',
  "& svg": {
    fontSize: theme.typography.pxToRem(40),
  }
})