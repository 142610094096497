import { FactionInfo } from './Factions';
import { Grid, Paper, Typography } from '@mui/material';
import * as styles from './factions.style';

interface FactionRowProps extends FactionInfo {
  visible: boolean,
}

export const FactionRow: React.FC<FactionRowProps> = ({ title, text, visible }) => (visible ? (<Grid xs={12} flex="0 0 100%" className={styles.row} item>
  <Paper>
    <Typography className={styles.textTitle} variant="h3" align="center">{title}</Typography>
    {text}
  </Paper>
</Grid>) : null)