import { Grid, Link } from '@mui/material';
import * as styles from './menuRowBody.styles';
import { Page, useNavigationStore } from '../../Stores/useNavigationStore';
import classnames from 'classnames';

interface Props {
  pageName?: Page,
  onClick?: () => void,
  children: React.ReactNode,
}

export const MenuBodyItem: React.FC<Props> = ({children, pageName, onClick = () => {}}) => {
  const { page, setPage } = useNavigationStore();

  const handleOpen = () => {
    console.info(pageName)
    typeof(pageName) !== 'undefined' ? setPage(pageName) : onClick()
  }

  return (
    <Grid item>
      <Link className={classnames(styles.button, { [styles.selected]: page === pageName })} onClick={handleOpen} underline="none">{children}</Link>
    </Grid>
  )
}