import * as styles from './main.style';
import { Box, Typography } from '@mui/material';
import { Arrow, ArrowDirection } from '../../Components/Arrow/Arrow';
import { Page, useNavigationStore } from '../../Stores/useNavigationStore';
import { useEffect, useRef } from 'react';

export const Main: React.FC = () => {
 const setPage = useNavigationStore((state) => state.setPage);
  const setReference = useNavigationStore((state) => state.setReference)
  const container = useRef(null);

  useEffect(() => setReference(Page.landing, container), [ container, setReference ])

 return (<Box component="article" ref={container} className={styles.root}>
   <Box className={styles.background}>
     <Box className={styles.title}>
       <Typography variant="h1">Reign of Sin</Typography>
       <Typography variant="h2">15 - 17 July, Suntaži</Typography>
     </Box>
   </Box>
   <Box className={styles.arrow}>
    <Arrow direction={ArrowDirection.up} onClick={() => setPage(Page.info)} />
   </Box>
 </Box>)
}

