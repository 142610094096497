import * as styles from './contacts.style';
import { Grid, Link, Paper, Typography } from '@mui/material';
import { Page } from '../../Stores/useNavigationStore';
import { useBreakpoint } from '../../theme/useBreakpoint';
import { Layout } from '../../Components/Layout/Layout';

/*
const MapComponent: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      // @ts-ignore
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  return (
      <div ref={ref} />
  );
)
 */

export const Contacts: React.FC = () => {
  const lg = useBreakpoint('lg');

  return (<Layout next={null} index={Page.contacts} prev={Page.factions} className={styles.root}>
      <Grid container className={styles.data} justifyContent={lg ? "flex-start" : "center"} alignContent={lg ? "top" : "top"}>
        <Grid item>
          <Paper className={styles.paper}>
              <Typography variant="h3" align="center">Contacts</Typography>
              <Typography align="left">Ask questions in English and Latvian to <Link href="https://www.facebook.com/alisovskis"><strong>Aivis Lisovskis</strong></Link> reignofsin.larp@gmail.com</Typography>
              <br />
              <Typography align="left">Ask questions in Russian to <Link href="https://www.facebook.com/german.levikov"><strong>German Levikov</strong></Link> reignofsin.larp@gmail.com</Typography>
              <br />
            <Typography align="left">Emergency contact <Link href="https://www.facebook.com/sandra.bogusha"><strong>Sandra Boguša</strong></Link> +371 27516176</Typography>
            <br />
            <Typography align="left">Email <strong>reignofsin.larp@gmail.com</strong></Typography>
              <br />
              <Typography variant="h4" align="left">
                Payment information
              </Typography>
              <Typography align="left">
                <strong>Account</strong> LV62HABA0551030651774
              </Typography>
              <Typography align="left">
                <strong>Owner</strong> Sandra Bogusa
              </Typography>
              <Typography align="left">
                <strong>Amount</strong> 40  euro
              </Typography>
              <Typography align="left">
                <strong>Payment details</strong> Donation for Cultist LARP
              </Typography>
            <br />
            <Typography>
              <strong>Coordinates of the game poligon:</strong> 56° 55' 59.0228" N 24° 56' 26.8379" E
            </Typography>
            {/*
              <Wrapper apiKey={"YOUR_API_KEY"} render={(status: Status) => {
                return <h1>{status}</h1>;
              }}>
                <MapComponent />
              </Wrapper>
              */}
          </Paper>
        </Grid>
      </Grid>
  </Layout>)
}