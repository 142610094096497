import * as styles from './factions.style';
import { Box, Button, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { ArrowColor } from '../../Components/Arrow/Arrow';
import { Page } from '../../Stores/useNavigationStore';
import { Faction, useSubMenuStore } from '../../Stores/useSubMenuStore';
import { FactionRow } from './FactionRow';
import { Layout } from '../../Components/Layout/Layout';
import { useBreakpoint } from '../../theme/useBreakpoint';
import classnames from 'classnames';

export interface FactionInfo {
  title: string,
  text: React.ReactNode,
  id: Faction,
  img?: string,
}

const factionInfo: FactionInfo[] = [
  {
    'title': 'Hunters',
    'id': Faction.hunters,
    'text': <Box>
      <Typography color="error" align="left"><strong>All spots for The Hunters are already full!</strong></Typography>
      <br />
      <Typography align="left">
        Hunters are an organisation formed by Cradles council and governed by the Raven family. Organisations main goal is to stop and control cults influence in the city and slums. To fulfil their duties hunters are allowed to use any means necessary.
      </Typography>
      <Typography align="left">
        Maria Raven, also known as Raven Horror is the current Hunter leader. She re-created rituals to select those few who can use certain approved technologies. To be able to use it, hunters are required to follow special laws to maintain their soul. If rituals are not enforced then losing your soul  will be the least of your problems.
      </Typography>
      <Typography align="left">
        Anyone who survives rituals and is still sane can become a hunter. Since the organisation has grown considerably not all members know each other. All hunters automatically become citizens of Cradle. Hunters are the only ones who are officially allowed to use weapons.
      </Typography>
      <br />
      <Typography align="left">
        <strong>Hunters are allowed to bring any weapon except nerf gun, bow, spear, shield. GMs keep the final word on weapon approval.</strong>
      </Typography>
      <Typography align="left">
        <strong>Hunters are allowed to use approved technologies, artefacts.</strong>
      </Typography>
      <Typography align="left">
        <strong>Players can join this faction before or during the game. Player count in this faction is limited before the game.</strong>
      </Typography>
    </Box>
  },
  {
    'title': 'Agents',
    'id': Faction.agents,
    'text': <Box>
      <Typography color="error" align="left"><strong>All spots for Agents are already full!</strong></Typography>
      <br />
      <Typography align="left">
        Agents are a radical splinter group of Hunters obsessed with research of technology, and they operate underground. Organisation is sponsored by one of the 7 Great Families from Cradle city. Their goal is an enigma.
      </Typography>
      <Typography align="left">
        Agents infiltrate any facility, cult, city governance to gain information and knowledge about technology. If it is possible to acquire artefacts in their possession they will certainly do so.
      </Typography>
      <Typography align="left">
        If they find out that someone was in contact with technology certain experiments can be performed on them even without their knowledge. People have side effects from these examinations.
      </Typography>
      <Typography align="left">
        Agents will neutralise anyone who stands in their way. Even hunters are not exempt!
      </Typography>
      <br />
      <Typography align="left">
        <strong>Agents are allowed to bring any weapon except bow, spear or shield. GMs keep the final word on weapon approval.</strong>
      </Typography>
      <Typography align="left">
        <strong>Agents can use any technology and artefacts by their special rules.</strong>
      </Typography>
      <Typography align="left">
        <strong>Players can join this faction before or during the game. Player count in this faction is limited before the game.</strong>
      </Typography>
    </Box>
  },
  {
    'title': 'The Ermitians',
    'id': Faction.cult1,
    'text': <>
      <Typography color="error" align="left"><strong>All spots for The Ermitians are already full!</strong></Typography>
      <br />
  <Typography align="left">
      They revere spirits that represent all the strong expressions of Nature. Storms, cunamis, volcanic eruptions. The world has been ripped apart by many such natural disasters, but the place where this cult originated from has been miraculously spared from all of them. When the third storm destroyed yet another neighbouring settlement, the founders understood - they had been chosen. If they serve the great Nature, it will spare them.
    </Typography>
  <Typography align="left">
      As they have seen from experience, if you don’t revere the great Nature - it will destroy you. Serve it, revere it and you will be spared from destruction. All others shall eventually be cleansed from the face of earth to satisfy the anger of the great Nature. And how would nature not be angry? Its face has been riddled with scars from man’s negligence and lust for power.
  </Typography>
  <Typography align="left">
      They welcome new disasters with awe. As they know, it’s judgement time for the heretics.
  </Typography>
  <Typography align="left">
      The cult believes - once the world has been sufficiently cleansed, the great Nature will return magic to them.
  </Typography>
  <Typography align="left">
      They despise anything relating to technology as it works against the will of the great Nature and have brought the world to this desecrated state. If they see you using technologies you will be a prime target for a sacrifice. As sacrificing impure people is the best way to appease the anger of the great Nature, at least for a short while.
  </Typography>
  <Typography align="left">
    Their goal is to return magic in its purest form on the face of the earth.
  </Typography>
      <br />
  <Typography align="left">
    <strong>Cultists can bring their own knives or daggers. GMs keep the final word on weapon approval.</strong>
  </Typography>
  <Typography align="left">
    <strong>Cultists can use magical artefacts.</strong>
  </Typography>
  <Typography align="left">
    <strong>Players can join this faction before or during the game. Player count in this faction is limited before the game.</strong>
  </Typography>
    </>,
  },
  {
    'title': 'Coven of Bacchanaly',
    'id': Faction.cult2,
    'text': <>
      <Typography color="error" align="left"><strong>All spots for Coven of Bacchanaly are already full!</strong></Typography>
      <br />
      <Typography align="left">
          Live today. Take every pleasure that is left in this world – drink wine, feast upon lavish meals and indulge in bodily pleasures. The world has already been completely ruined so make the last living moments worth it all.
        </Typography>
      <Typography align="left">
          You have to know how to enjoy your life right now. If you don’t feast and don’t live in the moment it’s an insult to God. Drink till you drop, eat till you have to vomit and have the wildest of orgies every night. In that way you prove that you are not a pathetic worm. You are a person, you are worthy to keep alive and for enjoying your life to the fullest you shall be rewarded with lavish gifts in return.
        </Typography>
      <Typography align="left">
          Those who hoard resources and don’t know how to properly enjoy them don’t deserve them. With that type of behaviour they have proved to be lesser life forms. If you find such people - rob them, take all of the gods given gifts for yourselves. You deserve it after all. Much more than a pathetic parasite. Even they have some purpose, at least providing pleasure for those exquisite enough to enjoy the brilliant ecstasy of pain.
        </Typography>
      <Typography align="left">
          Their goal is to enjoy every living minute, to indulge and feast. And to search for new and more grand ways to enjoy life. By revering their God they shall receive means to continue enjoying their life to the fullest.
      </Typography>
      <br />
      <Typography align="left">
        <strong>Cultists can bring their own knives or daggers. GMs keep the final word on weapon approval.</strong>
      </Typography>
      <Typography align="left">
        <strong>Cultists can use magical artefacts.</strong>
      </Typography>
      <Typography align="left">
        <strong>Players can join this faction before or during the game. Player count in this faction is limited before the game.</strong>
      </Typography>
    </>
  },
  {
    'title': 'Legion of Mortis',
    'id': Faction.cult3,
    'text': <>
      <Typography align="left">
        To survive in this world, you have to keep everyone in line. Military order is the key to survival. Otherwise, it’s pure chaos and insanity. Here order and gods’ wisdom will rule and guide them. Only by following the strict routine the god has given, you can keep your sanity.
      </Typography>
      <Typography align="left">
        The cult members are instructed by the priest to seek out potential sacrifices. People who have lost their minds make the best sacrifices. Their blood is turned into sanity panacea. The flesh cleansed from the impure blood serves as nourishment for the commune.
      </Typography>
      <Typography align="left">
        During the harshest of times when there was nothing to eat, the first priest received a vision. God told him of how to keep your sanity through a rigorous routine and gave him a way to turn corrupted people into a lavish and healthy meal.
      </Typography>
      <Typography align="left">
        All of the cult members are obligated to participate in the sacrificial ceremonies for the god.
      </Typography>
      <Typography align="left">
        Their goal is to survive in this wrecked world while keeping their sanity and sharing their ways with others.
      </Typography>
      <br />
      <Typography align="left">
        <strong>Cultists can bring their own knives or daggers. GMs keep the final word on weapon approval.</strong>
      </Typography>
      <Typography align="left">
        <strong>Cultists can use magical artefacts.</strong>
      </Typography>
      <Typography align="left">
        <strong>Players can join this faction before or during the game. Player count in this faction is limited before the game.</strong>
      </Typography>
    </>,
  },
  {
    'title': 'Civilians',
    'id': Faction.civil,
    'text':
      <Box>
        <Typography align="left">
          Common folk that arrived to the slums with hopes to get into Cradle city. For now they try to survive in the slums with whatever resources they can find. And resources are very limited.
        </Typography>
        <Typography align="left">
          They are not committed to any faction, yet. Cultists and agents might be interested in these souls. Hunters will seek strong willed ones to join their ranks. Or they might fall victims to the unforgiving slums of Cradle city.
        </Typography>
        <Typography align="left">
          They have ultimate freedom to create their own fate with all the consequences.
        </Typography>
        <br />
        <Typography align="left">
          <strong>Civilians are allowed to bring knives and daggers. GMs keep the final word on weapon approval.</strong>
        </Typography>
        <Typography align="left">
          <strong>When unknowing people use artefacts or technology there usually are consequences.</strong>
        </Typography>
        <Typography align="left">
          <strong>This is the default faction if the player hasn't joined any other at the beginning of the game.</strong>
        </Typography>
      </Box>
  },
];

export const Factions: React.FC = () => {
  const { setFaction, faction } = useSubMenuStore();

  const lgUp = useBreakpoint('lg');

  return (
    <Layout next={Page.contacts} index={Page.factions} prev={Page.intro} className={styles.root} arrowColor={ArrowColor.black}>
        <Grid container direction="row" className={styles.data} wrap={lgUp ? "nowrap" : "wrap"} alignItems="center" spacing={4}>
          {lgUp && (<Grid item className={styles.arrow} lg={4}>
            <img alt="Bottom decoration" src="/images/elements/subNavTop.png" className={styles.subNavTop} />
            <Paper className={styles.subMenu}>
              {factionInfo.map((currentFaction) => (<Button size="small" variant="text" fullWidth className={classnames(styles.subMenuButton, { [styles.active]: currentFaction.id === faction })} onClick={() => setFaction(currentFaction.id)}>{currentFaction.title}</Button>))}
            </Paper>
            <img alt="Top decoration" src="/images/elements/subNavBottom.png" className={styles.subNavBottom} />
          </Grid>)}
          {(!lgUp && (<Grid item xs={12} className={styles.tabs}>
            <Tabs classes={{ indicator: styles.indicator, scrollButtons: styles.scroller }} variant="scrollable" centered indicatorColor="secondary" allowScrollButtonsMobile selectionFollowsFocus value={faction} onChange={(event: React.SyntheticEvent, newValue: number) => {
              setFaction(newValue);
            }} aria-label="basic tabs example">
              {factionInfo.map((currentFaction) => (<Tab value={currentFaction.id} label={<Typography className={styles.tabText} variant="h3">{currentFaction.title}</Typography>} />))}
            </Tabs>
          </Grid>))}
          <Grid item lg={8} className={styles.factionContainer}>
            <Grid container>
                {factionInfo.map((factionData) => <FactionRow visible={factionData.id === faction} key={factionData.id} {...factionData} />)}
            </Grid>
          </Grid>
        </Grid>
    </Layout>)
}

