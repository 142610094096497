import { Page, useNavigationStore } from '../../Stores/useNavigationStore';
import * as styles from './layout.style';
import { Container, Grid } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { ArrowColor } from '../Arrow/Arrow';

interface Props {
  next: Page | null
  prev: Page
  children: React.ReactNode
  className: string
  index: Page,
  arrowColor?: ArrowColor,
}

export const Layout: React.FC<Props> = ({ index, className, arrowColor= ArrowColor.white, children }) => {
  const setReference = useNavigationStore((state) => state.setReference)
  const container = useRef(null);

  useEffect(() => setReference(index, container), [ container, index, setReference ])

  return (<Container ref={container} component="article" className={classnames(styles.root, className)}>
    <Grid container spacing={2} className={styles.data} direction="column" alignItems="center">
      <Grid xs item className={styles.container}>
        {children}
      </Grid>
    </Grid>
  </Container>)
}