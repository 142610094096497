import * as styles from './navigationRow.styles';
import { Grid } from '@mui/material';
import { MenuRowBody } from '../MenuRowBody/MenuRowBody';

export const NavigationRow: React.FC = () => {
  return (<Grid container className={styles.root} direction="row" wrap="nowrap" alignItems="flex-start" justifyContent="center" >
      <Grid item className={styles.leftCorner}>&nbsp;</Grid>
      <Grid xs item className={styles.menu}>
        <MenuRowBody />
      </Grid>
      <Grid item className={styles.rightCorner}>&nbsp;</Grid>
  </Grid>)
}