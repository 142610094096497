import {
  Alert,
  Button,
  Checkbox,
  Dialog, DialogContent,
  FormControl,
  FormControlLabel,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Field, Form, Formik, FormikErrors, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { RegisterState, useDialog } from '../../Stores/useDialog';
import { useBreakpoint } from '../../theme/useBreakpoint';
import * as styles from './registration.style';
import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle } from '@mui/lab';

interface FormValues {
  name: string
  email: string
  background: string
  reason: string
  charname: string
  phone: string
  faction: string
  is18: boolean
}

const RegistrationInner: React.FC = () => {
  const { errors, values, setFieldValue, isSubmitting } = useFormikContext<FormValues>()
  const { toogleRegister } = useDialog();

  const selectChange = useCallback((e: SelectChangeEvent) => {
    setFieldValue("faction", e.target.value);
  }, [ setFieldValue ])

  return (
      <Form>
        <Grid container justifyContent="center" spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3" align="center">Registration</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="name"
              label="Full name"
              as={TextField}
              autoComplete={false}
              error={!!errors.name}
              helperText={errors.name || " "}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name="email"
              label="Email"
              autoComplete={false}
              as={TextField}
              error={!!errors.email}
              helperText={errors.email || " "}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name="phone"
              label="Phone"
              autoComplete={false}
              as={TextField}
              error={!!errors.phone}
              helperText={errors.phone || " "}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.preventDefault();
                const { value } = e.target;
                const regex = /^([+])?[0-9]*$/i;
                if (regex.test(value.toString())) {
                  setFieldValue("phone", value);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name="charname"
              label="Character name"
              autoComplete={false}
              error={!!errors.charname}
              helperText={errors.charname || " "}
              as={TextField}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="label">Faction</InputLabel>
              <Select
                labelId="label"
                name="faction"
                label="Faction"
                value={values.faction}
                className={styles.faction}
                onChange={selectChange}
              >
                <MenuItem value="hunters">Hunters</MenuItem>
                <MenuItem value="agents">Agents</MenuItem>
                <MenuItem value="the-ermitians">The Ermitians</MenuItem>
                <MenuItem value="coven-of-bacchanaly">Coven of Bacchanaly</MenuItem>
                <MenuItem value="legion-of-mortis">Legion of Mortis</MenuItem>
                <MenuItem value="civilians">Civilians</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={styles.reason}>
            <Field
              fullWidth
              name="reason"
              label="How you joined the faction"
              as={TextField}
              multiline
              error={errors.reason}
              maxRows={4}
              minRows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="background"
              label="Background"
              as={TextField}
              multiline
              error={errors.background}
              maxRows={4}
              minRows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              as={FormControlLabel}
              type="checkbox"
              disableTypography
              name="is18"
              control={<Checkbox />}
              label={<Typography color="primary">Confirm that I’m 18+ years old</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            {errors.is18 && (<Alert severity="error">
              {errors.is18}
            </Alert>)}
          </Grid>
          <Grid item>
            <Button disabled={isSubmitting} type="submit" variant="contained" color="secondary">Register</Button>
          </Grid>
        </Grid>
        <IconButton
          color="error"
          onClick={toogleRegister}
          aria-label="close"
          className={styles.close}
        >
          <CloseIcon fontSize="large"/>
        </IconButton>
      </Form>
      )
}

export const Registration: React.FC  = () => {
  const [ success, setSuccess ] = useState(false);
  const { toogleRegister, registerState } = useDialog();
  const mdUp = useBreakpoint('md')

  const handleClose = useCallback(() => {
    toogleRegister();
    setSuccess(false);
  }, [ toogleRegister ]);

  return (<Dialog
      open={registerState === RegisterState.open}
      onClose={handleClose}
      fullScreen={!mdUp}
      PaperProps={{
        className: styles.paper
      }}
    >
      <DialogContent className={styles.container}>
        {success && <Grid container spacing={2} direction="column" alignItems="flex-end">
          <Grid item xs>
            <Alert severity="success">
              <AlertTitle>Congrats!</AlertTitle>
              You have registered for the game!
            </Alert>
          </Grid>
          <Grid item xs>
            <Button variant="outlined" onClick={handleClose}>Ok!</Button>
          </Grid>
        </Grid>}
        {!success && <Formik
            validateOnBlur={false}
            initialValues={{
              name: '',
              email: '',
              background: '',
              reason: '',
              charname: '',
              phone: '',
              faction: 'hunters',
              is18: false
            } as FormValues}
            validate={(values) => {
              const error: FormikErrors<FormValues> = {};

              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                error.email = 'Invalid email address';
              }

              if (!/^([+])?([0-9]{3})?[0-9]{7,8}$/i.test(values.phone)) {
                error.phone = 'Invalid phone number';
              }

              if (values.name.length < 3) {
                error.name = 'Please enter full name';
              }

              if (values.charname.length < 1) {
                error.charname = 'Invalid character name';
              }

              if (!values.is18) {
                error.is18 = 'You must be at least 18 years old to participate';
              }

              if (values.faction === "agents") {
                error.is18 = 'All Agents spots are already taken! Please choose different faction!';
              }

              if (values.faction === "coven-of-bacchanaly") {
                error.is18 = 'All Coven of Bacchanaly spots are already taken! Please choose different faction!';
              }

              if (values.faction === "the-ermitians") {
                error.is18 = 'All Ermitians spots are already taken! Please choose different faction!';
              }

              if (values.faction === "hunters") {
                error.is18 = 'All Hunters spots are already taken! Please choose different faction!';
              }

              return error;
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              };

              fetch('https://api.kaslauns.lv/handleRegistration.php', requestOptions)
                .then(async (response) => {
                  if (response.status === 200) {
                    setSuccess(true);
                  } else {
                    const text = await response.text();
                    setErrors({ 'is18': text });
                  }
                })
                .catch(() => {
                  setErrors({ 'is18': 'Failed to submit, please try again' })
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
          <RegistrationInner />
        </Formik>}
      </DialogContent>
  </Dialog>
  )
}