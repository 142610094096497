import { List } from '@mui/material';
import { useDrawerState } from '../../Stores/useMenuDrawer';
import { MenuBodyItem } from './MenuBodyItem';
import { Page, useNavigationStore } from '../../Stores/useNavigationStore';
import { useDialog } from '../../Stores/useDialog';

interface Props {
  close?: boolean
}

export const MenuBody: React.FC<Props> = ({ close = false }) => {
  const { toggleDrawer } = useDrawerState();
  const { setPage } = useNavigationStore();
  const { toogleRegister } = useDialog();

  const openPage = (page: Page) => {
    setPage(page);
    toggleDrawer();
  }

 return (<List>
   <MenuBodyItem onClick={toogleRegister}>Register</MenuBodyItem>
   <MenuBodyItem onClick={() => openPage(Page.info)}>About</MenuBodyItem>
   <MenuBodyItem onClick={() => openPage(Page.intro)}>the World</MenuBodyItem>
   <MenuBodyItem onClick={() => openPage(Page.factions)}>Factions</MenuBodyItem>
   <MenuBodyItem onClick={() => openPage(Page.contacts)}>Contacts</MenuBodyItem>
  </List>)
}